@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 40px;
	/* width: 400px; */
}

.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}
.work-second-col {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.sub-duration-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	/* margin-top: -3px; */
	color: var(--secondary-color);
}

.work-subtitle {
	font-size: 12px;
	color: var(--secondary-color);
	/* padding-top: 22px; */
	padding-left: 20px;
}

.work-duration {
	/* width: 350px; */
	font-size: 12px;
	/* padding-top: 20px; */	
	right: 0;
	text-align: right;
	color: var(--tertiary-color);
}
